import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import { ImageWrapper } from '../../ImageWrapper/ImageWrapper';
import './InfoFooter.css';
import { useState } from 'react';

export const getImageUrl = (category: 'food' | 'activity') => {
  if (!!category && category === 'food')
    return './icons/restaurantMarker-new.png';
  return './icons/attractionMarker-new.png';
};

export const InfoFooter = ({
  content,
  showDetails,
  playVideo,
  isHideVisible,
}: {
  content: any;
  showDetails: () => void;
  playVideo: () => void;
  isHideVisible: boolean;
}) => {
  const isMobile = useIsMobile();

  const scaleValue =
    window.innerWidth > 500 ? 1 : window.innerWidth > 400 ? 0.9 : 0.8;

  const [isInfoFooterVisible, setIsInfoFooterVisible] = useState(true);
  // const dynamicMarginBottom = window.innerHeight > 667 ? '6vh' : '3vh';

  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        bottom: 0,
        height: '400px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'column',
        // marginBottom: isMobile ? dynamicMarginBottom : '6vh',
        // marginBottom: dynamicMarginBottom,
        zIndex: 99,
      }}
    >
      {isHideVisible && (
        <div
          className="wrapper-info-footer"
          style={{ transform: `scale(${scaleValue})` }}
        >
          <div
            className={`info-footer-hide-show-wrapper ${
              !isMobile && 'desktop'
            }`}
            style={{}}
            onClick={() => {
              setIsInfoFooterVisible(!isInfoFooterVisible);
              playVideo();
            }}
          >
            <span className="custom-badge info-footer-hide-show">
              {isInfoFooterVisible ? 'Hide' : 'Show'}
            </span>
          </div>
          <div
            style={{
              height: '200px',
              width: isInfoFooterVisible ? '100%' : '1px',
            }}
          >
            {isInfoFooterVisible && (
              <div
                className={`info-footer-bottom-wrapper ${
                  !isMobile && 'desktop'
                }`}
              >
                <>
                  <div className="info-footer">
                    <div className="info-footer-content">
                      <div className="info-footer-image">
                        <ImageWrapper
                          src={getImageUrl(content?.category)}
                          className="info-footer-image-wrapper"
                        />
                      </div>
                      <div className="info-footer-item">
                        <div className="info-footer-place">in Tokyo</div>
                        <div className="info-footer-name">{content.name}</div>
                      </div>
                      <div className="info-footer-estimate">
                        <div className="info-footer-review">
                          {content.review} ⭐
                        </div>
                        {!!content.price && (
                          <div className="info-footer-price">
                            <div className="info-footer-price-value">
                              ~US${content.price}
                            </div>
                            <div>(estimated)</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="footer-info-buttons">
                      <div
                        className="custom-badge info-footer-details"
                        onClick={showDetails}
                      >
                        More Details
                      </div>
                      <a
                        className="custom-badge active info-footer-site"
                        href={content.url_book_place}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Official Website
                      </a>
                    </div>
                    <div className="info-footer-desc">{`“${content.summary}”`}</div>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
      )}
      <a
        style={{
          width: isMobile ? '50px' : '100px',
          background: 'white',
          height: isMobile ? '210px' : '240px',
          position: 'absolute',
          zIndex: 7,
          bottom: 0,
          right: 0,
          opacity: 0,
        }}
        target="_blank"
        rel="noreferrer"
        href={content.media[0].url}
      ></a>
    </div>
  );
};
