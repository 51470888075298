import { useState } from 'react';
import { signal } from '@preact/signals-core';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { motion, AnimatePresence } from 'framer-motion';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
import Navbar from '../NavbarNew';
import { GridContainer } from './GridContainer/GridContainer';
import { TiktokItem } from './TiktokItem/TiktokItem';
import { useGridData } from './hooks/useGridData';
import { ImageWrapper } from './ImageWrapper/ImageWrapper';
import { useNavigate } from 'react-router-dom';

import './DiscoverMenu.css';
import ShareModal from './ShareModal/ShareModal';

export const showDiscovery = signal(true);
export const isShareModalVisible = signal(false);

export type DiscoverButtons = 'trips' | 'activities' | 'restaurants';

export const DiscoverMenu = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isSharedVisilbe, setIsSharedVisilbe] = useState(false);
  const navigate = useNavigate();

  useSignalEffect(() => {
    setIsVisible(showDiscovery.value);

    setIsSharedVisilbe(isShareModalVisible.value);
  });

  const isMobile = useIsMobile();

  // const initialAnimation = isMobile
  //   ? { x: -window.innerWidth }
  //   : { y: -window.innerHeight, x: '-50%' };
  // const animation = isMobile ? { x: 0 } : { y: 0, x: '-50%' };
  // const exit = isMobile
  //   ? { x: -window.innerWidth }
  //   : { y: -window.innerHeight, x: '-50%' };

  const handleBackButtonClick = () => {
    // showDiscovery.value = false;
    navigate('/home');
  };

  const [activeButton, setActiveButton] =
    useState<DiscoverButtons>('activities');

  const data = useGridData();
  const rawItems = [...data.activities, ...data.restaurants].map(
    (item, index) => ({ ...item, storyIndex: index + 1 }),
  );

  const restaurants = rawItems.filter((item) => item.category === 'food');
  const activities = rawItems.filter((item) => item.category === 'activity');

  const dataToShow = {
    activities,
    restaurants,
  };

  const [currentStoryIndex, setCurentStoryIndex] = useState<number | undefined>(
    undefined,
  );
  const [isStoriesVisible, setIsStoriesVisible] = useState(false);

  const handleShowStories = (storyIndex: number) => {
    setCurentStoryIndex(storyIndex);
    setIsStoriesVisible(true);
  };

  const handleNext = (currentValue: number) => {
    setCurentStoryIndex(-1);
    setTimeout(() => {
      setCurentStoryIndex((_prev) => {
        if (currentValue === rawItems.length) {
          return 1;
        }
        return currentValue! + 1;
      });
    }, 50);
  };

  const handlePrev = (currentValue: number) => {
    setCurentStoryIndex(-1);
    setTimeout(() => {
      setCurentStoryIndex((_prev) => {
        return currentValue! - 1;
      });
    }, 50);
  };

  return (
    <>
      {/* <AnimatePresence>
        {isVisible && (
          <motion.div
            transition={{
              duration: 0.25,
              bounce: false,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="discover-menu-backdrop"
            style={{ cursor: 'pointer', zIndex: 9999 }}
            onClick={() => {
              if (!isMobile) {
                showDiscovery.value = false;
              }
            }}
          />
        )}
      </AnimatePresence> */}
      {isSharedVisilbe && <ShareModal />}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            transition={{
              ease: 'easeInOut',
              duration: 0.4,
              type: 'spring',
              bounce: false,
            }}
            // initial={initialAnimation}
            // animate={animation}
            // exit={exit}
            className={`discover-menu ${isMobile ? '' : 'desktop'}`}
          >
            <div className="discover-menu--container">
              <Navbar
                onBackButtonClick={handleBackButtonClick}
                style={{ margin: '0 auto', padding: 0 }}
                shouldShowDiscovery
              />
            </div>

            <div className="discover-menu--container content">
              <h3 className="discover-menu-title">Tokyo</h3>
              <div className="discover-menu-buttons">
                {/* <div
                  className={`discover-menu-buttons--item ${
                    activeButton === 'trips' && 'active'
                  }`}
                  onClick={() => setActiveButton('trips')}
                >
                  <span className="discover-menu-buttons--emoji">🗺️</span>
                  <span className="discover-menu-buttons--text">Trips</span>
                </div> */}
                <div
                  className={`discover-menu-buttons--item ${
                    activeButton === 'activities' && 'active'
                  }`}
                  onClick={() => setActiveButton('activities')}
                >
                  <ImageWrapper src="./icons/attractionMarker-new.png" />
                  <span className="discover-menu-buttons--text">Activity</span>
                </div>
                <div
                  className={`discover-menu-buttons--item ${
                    activeButton === 'restaurants' && 'active'
                  }`}
                  onClick={() => setActiveButton('restaurants')}
                >
                  <ImageWrapper src="./icons/restaurantMarker-new.png" />
                  <span className="discover-menu-buttons--text">
                    Restaurant
                  </span>
                </div>
              </div>

              {/* GRID BOTTOM SECTION */}
              <GridContainer
                activeButton={activeButton}
                setActiveButton={setActiveButton}
                dataToShow={dataToShow}
                handleShowStories={handleShowStories}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {currentStoryIndex && currentStoryIndex !== rawItems.length + 1 && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            background: '#000',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 999999,
          }}
        >
          {currentStoryIndex !== -1 && (
            <TiktokItem
              content={
                isStoriesVisible
                  ? rawItems.find(
                      (story) => story.storyIndex === currentStoryIndex,
                    )
                  : undefined
              }
              goNext={handleNext}
              goPrev={handlePrev}
              handleClose={() => {
                setIsStoriesVisible(false);
                setCurentStoryIndex(undefined);
              }}
              currentStoryIndex={currentStoryIndex}
            />
          )}
        </div>
      )}
    </>
  );
};
